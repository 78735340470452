import React from 'react';
import { View, Text, Dimensions, ActivityIndicator, StyleSheet, FlatList, TextInput, TouchableOpacity } from 'react-native-web';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class DriverOrderDetail extends React.Component {

  constructor() {
    super();
    this.state = {
      orders: [],
      isLoading: true,
      driverName: 'Lõpetamata',
      driverNumber: 'Lõpetamata',
      offersList: [],
      savedText: 'Salvesta',
      savedColor: global.mainBlue
    }
  }

  componentDidMount() {
    var auth = firebase.auth();

    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        const { orderID } = this.props.location.state
        this.setState({orderID: orderID}, function() {
          this.getDriveData();
        });
      }
    });

  }

  getDriveData() {
    firebase.database().ref('orders').child(this.state.orderID).on('value', snap => {
      firebase.database().ref('users/shops').child(snap.val().shopID).once('value', shop => {
        firebase.database().ref('users/drivers').child(snap.val().workerID == '' ? 'missing' : snap.val().workerID).once('value', user => {
      this.setState({
        company: snap.val().workerCompany,
        placeName: snap.val().placeName,
        loadingDate: snap.val().loadingDate,
        loadingTime: snap.val().loadingTime,
        deliveryDate: snap.val().deliveryDate,
        deliveryFrom: snap.val().deliveryFrom,
        deliveryTo: snap.val().deliveryTo,
        level: snap.val().level,
        extralevel: snap.val().extralevel,
        packages: snap.val().packages,
        utilize: snap.val().utilize,
        twoPersons: snap.val().twoPersons,
        comment: snap.val().comment,
        price: snap.val().price,
        arrivingDate: snap.val().arrivingDate,
        arrivingTime: snap.val().arrivingTime,
        problem: snap.val().problem,
        problemDesc: snap.val().problemDesc,
        driverComment: snap.val().driverComment,
        workerID: snap.val().workerID,
        finished: snap.val().finished,
        extrapack: snap.val().extrapack,
        phone: snap.val().phone,
        code: snap.val().code,
        shopAddress: snap.val().shopAddress,
        apartment: snap.val().apartment,
        orders: snap.val().orders,
        customerPays: snap.val().customerPays,
        orderDate: snap.val().orderDate,
        orderTime: snap.val().orderTime,
        shopName: snap.val().shopName,
        shopWorker: shop.val().contactName,
        shopPhone: shop.val().contactPhone,
        driverName: user.val().driverName,
        driverPhone: user.val().driverNumber,
        shopID: snap.val().shopID,
        codeSent: snap.val().codeSent,
        period: snap.val().period,
        archived: snap.val().archived,
        adminComment: snap.val().adminComment
      }, function() {
        this.loadOffers()
        this.setState({isLoading: false});
      })
      })
      })
    })
  }

  loadOffers() {
    firebase.database().ref('orderOffers').child(this.state.orderID).on('value', offers => {
      let offersList = []
      offers.forEach(child => {
        offersList.push({
          offerID: child.key,
          workerID: child.val().workerID,
          workerCompany: child.val().workerCompany,
          deliveryDate: child.val().deliveryDate,
          deliveryTo: child.val().deliveryTo,
          deliveryFrom: child.val().deliveryFrom,
          loadingDate: child.val().loadingDate,
          loadingTime: child.val().loadingTime,
          price: child.val().price
        })
      })
      console.log(offersList)
      this.setState({offersList: offersList}, function() {
        this.setState({isLoading: false})
      })
    })
  }

  saveComment() {
    firebase.database().ref('orders').child(this.state.orderID).update({
      adminComment: this.state.adminComment
    })
    this.setState({savedText: 'Salvestatud', savedColor: 'red'})
    setTimeout(() => {
      this.setState({savedText: 'Salvesta', savedColor: global.mainBlue})
    }, 1350)
  }

  render() {

    return (
      <div style={{backgroundColor: '#F4F4F4', height: '100%'}}>
        <Header />

        <div style={{backgroundColor: '#F4F4F4', height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'flex-start', padding: 5}}>
            {this.state.workerID == '' && this.state.archived == false ? (
              <div style={{flexDirection: 'column', textAlign: 'right'}}>
                <text style={{fontFamily: 'Montserrat-Regular', color: '#2424d2'}}>Ootel</text>
              </div>
            ):(
              <div style={{flexDirection: 'column', textAlign: 'right'}}>
                <text style={{fontFamily: 'Montserrat-Regular', color: '#2424d2'}}> </text>
                <text style={{fontFamily: 'Montserrat-Regular', color: '#2424d2', marginRight: 5}}>{this.state.company}</text>
                <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, marginRight: 5}}>{this.state.driverName}</text>
                <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>{this.state.driverPhone}</text>
              </div>
            )}
            {this.state.finished &&
            <div style={{flexDirection: 'column', borderBottomWidth: 0.5, borderBottomColor: 'black', width: '100%', marginBottom: 10}}>
              <text style={{fontFamily: 'Montserrat-Regular', color: global.mainBlue, fontSize: 14, marginRight: 5}}>Kaup üle antud</text>
              <text style={{color: 'black', fontFamily: 'Montserrat-Light', fontSize: 14}}>{this.state.finished ? this.state.arrivingDate + ' kell ' + this.state.arrivingTime : 'Kaup ei ole veel kohale toimetatud!'}</text>
            </div>
            }
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '180', justifyContent: 'flex-start'}}>

              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', border: '1px solid black', width: '100%', padding: 5, paddingBottom: 0, backgroundColor: 'white'}}>
                <div style={{display: 'flex', flexDirection: 'column', marginRight: 10}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>ID:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.orderID}</text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Aeg:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.orderDate}{this.state.orderTime}</text>
                  </div>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Esitas:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.shopName} / {this.state.shopWorker} / {this.state.shopPhone}</text>

                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Pealelaadimise aadress:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.shopAddress}</text>

                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Tarne aadress:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.placeName}</text>
                  {this.state.apartment && (
                    <div>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Korter/ruum nr.:</text>
                    <text style={{color: 'black', fontFamily: 'Montserrat-Light', fontSize: 12}}>{this.state.apartment}</text>
                    </div>
                  )}
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Tellimuse nr/nimetus</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.orders}</text>

                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Kontaktnumber:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.phone}</text>

                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Turvakood:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>{this.state.code} {this.state.codeSent ? 'Saadetud' : 'Saatmata'}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Pealelaadimine alates:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.loadingDate} kell {this.state.loadingTime}</text>

                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Mahalaadimine:</text>
                  {this.state.period > 0 ? (
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.period == 0 && 'Pealelaadimise kuupäeva jooksul'}{this.state.period == 1 && 'Pealelaadimisele järgneva päeva jooksul'}{this.state.period == 5 && '5 päeva jooksul'}</text>
                  ):(
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.deliveryDate} kell {this.state.deliveryFrom} - {this.state.deliveryTo}</text>
                  )}
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Pakkide arv:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.extrapack > 10 ? this.state.extrapack : this.state.packages}</text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Korrus:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.extralevel > 9 ? this.state.extralevel : this.state.level}</text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Kahe mehe vedu:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.twoPersons ? 'Jah' : 'Ei'}</text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Utiliseerimine:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.utilize ? 'Jah' : 'Ei'}</text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Teenuse maksumus:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.price} €</text><text style={{fontFamily: 'Montserrat-Regular', color: 'red', fontSize: 14, marginRight: 5}}>{this.state.customerPays ? ' Tasub klient' : ' Tasub kauplus'}</text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Kommentaar:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.comment}</text>
                  </div>
                </div>
              </div>

            </div>

            <div style={{display: 'flex', flexDirection: 'column', width: '100%', border: '1px solid black', justifyContent: 'space-between', marginTop: 5}}>
              {this.state.offersList.map(offer => {
                return (
                  <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 90, border: '0.1px dashed grey', alignItems: 'center', padding: 1, justifyContent: 'space-between'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, marginLeft: 10}}>{offer.workerCompany}</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, marginLeft: 10}}>{offer.loadingDate} {offer.loadingTime}</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, marginLeft: 10}}>{offer.deliveryDate} {offer.deliveryTime}</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, color: '#e53051', marginLeft: 10}}>{offer.price} €</text>
                  </div>
                );
              })}
            </div>

          </div>
        </div>
      </div>
    );

  }
}

export default DriverOrderDetail;

const styles = StyleSheet.create({
  shadows: {
    shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 3
  },
  smallIcon: {
    width: 20,
    height: 20,
    marginLeft: 5
  },
})
