import React, { Component } from 'react';
import { View, Text, Dimensions, FlatList, TouchableOpacity, ActivityIndicator, Image } from 'react-native-web';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class DriverArchive extends React.Component {

  constructor() {
    super();
    this.state = {
      orders: [],
      isLoading: true,
      count: 0,
    }
  }

  componentDidMount() {

    var auth = firebase.auth();

    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        const { driverID } = this.props.location.state;
        const adminID = auth.currentUser.uid;
        this.setState({driverID: driverID, adminID: adminID}, function() {
          this.getOrders();
        })
      }
    });
  }

  getOrders() {
    firebase.database().ref('archive').child(this.state.driverID).on('value', data => {
      firebase.database().ref('users/drivers').child(this.state.driverID).on('value', user => {
      var orders = [];
      const count = data.numChildren();
      const company = user.val().company
      const driver = user.val().driverName
      const rate = Number(user.val().rate)
      let sum = 0
      let shop = 0
      let total = 0
      data.forEach(child => {
          if(!child.val().payd && !child.val().customerPays) {
            shop = shop + Number(child.val().price)
          }
          if(!child.val().payd && child.val().customerPays) {
            sum = sum + Number(child.val().price)
          }
          total = total + Number(child.val().price)
          orders.push({
            from: child.val().shopAddress,
            to: child.val().placeName,
            orderDate: child.val().deliveryDate,
            orderTime: child.val().deliveryTime,
            orderID: child.key,
            price: Number(child.val().price),
            customerPays: child.val().customerPays != null ? child.val().customerPays : !child.val().byCard,
            payd: child.val().payd,
            canceled: child.val().canceled,
            type: child.val().type,
            shopID: child.val().shopID
          });
    })
      this.setState({orders: orders.sort((a, b) => b.orderID - a.orderID), count: count, company: company, driver: driver, sum: sum, rate: rate, shop: shop, total: total}, function() {
        this.setState({isLoading: false})
      })
    })
    })
  }

  status(canceled) {
    if (!canceled) {
      return <Text style={{color: 'green'}}>Lõpetatud</Text>
    } else {
      return <Text style={{color: 'red'}}>Katkestatud</Text>
    }

  }

  makePayd(item, shopID) {
    firebase.database().ref('archive').child(this.state.driverID).child(item).update({
      payd: true,
      madePayd: this.state.adminID
    })
    firebase.database().ref('archive').child(shopID).child(item).update({
      payd: true,
      madePayd: this.state.adminID
    })
  }

  makeFinished(item) {
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var date = new Date().getDate();
    var kuu = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if(min < 10) {
      var minute = "0" + min;
    } else {
      var minute = min;
    }
    if(kuu < 10) {
      var month = "0" + kuu;
    } else {
      var month = kuu;
    }
    const finishDate = date + '/' + month + '/' + year;
    const finishTime = hours + ':' + minute;

    firebase.database().ref('orders').child(item).update({
      finished: true,
      arrivingDate: finishDate,
      arrivingTime: finishTime,
      archived: true,
      endedBy: this.state.adminID
    })
  }

  render() {

    return (
      <div>
        <Header />
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', border: '1px solid black', justifyContent: 'space-between'}}>
        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Tellimusi kokku: <text style={{fontWeight: 'normal', color: 'red'}}>{this.state.count}</text></text>
        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Kogusumma: <text style={{fontWeight: 'normal', color: 'red'}}>{this.state.total} €</text></text>
        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Teenustasud (v.a kaupluse maksed): <text style={{fontWeight: 'normal', color: 'red'}}>{(this.state.sum * this.state.rate).toFixed(2)} €</text></text>
        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Meie tasumata arved: <text style={{fontWeight: 'normal', color: 'red'}}>{((this.state.shop - (this.state.shop * this.state.rate))).toFixed(2)} €</text></text>
        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Tasumata arveid: <text style={{fontWeight: 'normal', color: 'red'}}>{((this.state.sum * this.state.rate) - (this.state.shop - (this.state.shop * this.state.rate))).toFixed(2)} €</text></text>
          {this.state.orders.map(item => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', width: '100%', border: '0.1px dashed grey', alignItems: 'center', padding: 5, justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                  {item.type == 'move' &&
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', color: 'red', fontSize: 14}}>Eraisiku tellimus</text>
                  }
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}>{item.from}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}> -> {item.to}</text>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>Lõpetati {item.orderDate} kell {item.orderTime}</text>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>ID: {item.orderID}</text>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                  <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Staatus: </text>
                  {item.archived && item.workerCompany == '' ? (
                    <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>Katkestatud!</text>
                  ):(
                  <div>
                  {this.status(item.canceled)}
                  </div>
                  )}
                </div>
                {item.workerCompany != '' &&
                <div style={{flexDirection: 'column', justifyContent: 'space-between', width: '30%'}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, marginRight: 5}}>Arve: </text>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'red'}}>{(item.price * this.state.rate).toFixed(2)} € </text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    {!item.payd ?
                    <text style={{fontFamily: 'Montserrat-Light', color: 'red', fontSize: 12}}>Maksmata</text>
                    :
                    <text style={{fontFamily: 'Montserrat-Regular', color: 'green', fontSize: 14}}>Makstud</text>
                    }
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    {item.customerPays ?
                    <text style={{fontFamily: 'Montserrat-Light', color: 'red', fontSize: 12, fontWeight: 'bold'}}> Maksab kuller!</text>
                    :
                    <text style={{fontFamily: 'Montserrat-Light', color: 'green', fontSize: 12}}>Maksab kauplus!</text>
                    }
                  </div>
                  {!item.payd && item.customerPays && (
                    <div>
                  <TouchableOpacity
                    onPress={() => this.makePayd(item.orderID, item.shopID)}>
                    <div style={{display: 'flex', flexDirection: 'row', width: 100, flexDirection: 'row', padding: 5, border: '0.5px solid black', alignItems: 'center', justifyContent: 'center',  borderRadius: 10, marginRight: 5}}>
                      <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Makstud</text>
                    </div>
                  </TouchableOpacity>
                  </div>
                  )}
                  {item.inProgress && !item.archived && !item.finished && (
                  <div>
                  {this.state.confirmCancelID == item.orderID ? (
                    <div style={{flexDirection: 'row'}}>
                      <TouchableOpacity
                        onPress={() => this.makeCanceled(item.orderID)}>
                        <div style={{flexDirection: 'row', padding: 5, borderWidth: 1, borderRadius: 10, borderColor: 'red', marginRight: 5}}>
                          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 10, color: 'red'}}>Kinnita</text>
                        </div>
                      </TouchableOpacity>
                      <textInput
                        onChangetext={(newPrice) => this.setState({newPrice})}
                        style={{width: 60, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', color: 'red', textAlign: 'center'}}
                        placeholder={this.state.newPrice}
                      />
                      <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>€</text>
                    </div>
                  ):(
                    <TouchableOpacity
                      onPress={() => this.setState({confirmCancelID: item.orderID, newPrice: item.price})}>
                      <div style={{display: 'flex', width: 100, flexDirection: 'row', padding: 5, alignItems: 'center', justifyContent: 'center', border: '0.5px solid black', marginRight: 5}}>
                        <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Katkesta</text>
                      </div>
                    </TouchableOpacity>
                  )}
                  </div>
                  )}
                </div>
                }
              <div>
                <Link className="nav-item nav-link" to={{pathname: '/driverArchiveDetail', state: { orderID: item.orderID, driverID: this.state.driverID }}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 16, color: '#e53051'}}>Detailid</text>
                </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DriverArchive;
