import React, { Component } from 'react';
import { View, Text, Dimensions, FlatList, TouchableOpacity, ActivityIndicator, Image } from 'react-native-web';
import Header from '../helpers/header';
import firebase from 'firebase';
import {Link} from '../routing';

class Transport extends React.Component {

  constructor() {
    super();
    this.state = {
      transportList: [],
      isLoading: true
    }
    var auth = firebase.auth();

    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        this.gettransports()
      }
    });
  }

  gettransports() {
    firebase.database().ref('transports').on('value', data => {
      var transportList = [];
      const count = data.numChildren();
      data.forEach(child => {
        transportList.push({
          id: 'id'
        });
      })
      this.setState({transportList: transportList}, function() {
        this.setState({isLoading: false})
      })
    })
  }

  sendForm = () => {
    firebase.database().ref('transports').push({
      date: this.state.date,
      from: this.state.from,
      to: this.state.to,
      kaup: this.state.kaup,
      client: this.state.client,
      price: this.state.price,
      card: this.state.card
    })
  }

  render() {

    return (
      <div>
        <Header />
        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', height: '100%', justifyContent: 'flex-start'}}>
          <input
            onChangetext={(date) => this.setState({date})}
            style={{width: 100, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', fontSize: 12, textAlign: 'center'}}
            placeholder={'kuupäev'}
          />
          <input
            onChangetext={(from) => this.setState({from})}
            style={{width: 160, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', fontSize: 12, textAlign: 'center'}}
            placeholder={'pealelaadimine'}
          />
          <input
            onChangetext={(to) => this.setState({to})}
            style={{width: 160, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', fontSize: 12, textAlign: 'center'}}
            placeholder={'mahalaadimine'}
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'flex-start'}}>
          <input
            onChangetext={(kaup) => this.setState({kaup})}
            style={{width: 420, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', fontSize: 12, textAlign: 'center'}}
            placeholder={'kaup'}
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'flex-start'}}>
          <input
            onChangetext={(client) => this.setState({client})}
            style={{width: 260, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', fontSize: 12, textAlign: 'center'}}
            placeholder={'klient'}
          />
          <input
            onChangetext={(price) => this.setState({price})}
            style={{width: 80, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', fontSize: 12, textAlign: 'center'}}
            placeholder={'hind'}
          />
          <input
            onChangetext={(card) => this.setState({card})}
            style={{width: 80, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', fontSize: 12, textAlign: 'center'}}
            placeholder={'maksemeetod'}
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'flex-start'}}>
          <button
            style={{display: 'felx', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 420, height: 30, backgroundColor: 'green'}}
            onClick={this.sendForm}
          >
          <text style={{color: 'white'}}>Sisesta</text>
          </button>
        </div>
        <div>
        {this.state.transportList.map(item => {
          return (
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', border: '0.1px dashed grey', alignItems: 'center', padding: 5, justifyContent: 'space-between'}}>
              <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}>{item.from}</text>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}> -> {item.to}</text>
                <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>Esitati {item.orderDate} kell {item.orderTime}</text>
                <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>ID: {item.orderID}</text>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Staatus: </text>
                {item.archived && item.workerCompany == '' ? (
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>Katkestatud!</text>
                ):(
                <div>
                {this.status(item.inProgress, item.finished, item.workerCompany, item.archived)}
                </div>
                )}
              </div>
              {item.workerCompany != '' &&
              <div style={{flexDirection: 'column', justifyContent: 'space-between', width: '30%'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, marginRight: 5}}>Arve: </text>
                  <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'red'}}>{(item.price * this.state.rate).toFixed(2)} € </text>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  {!item.payd ?
                  <text style={{fontFamily: 'Montserrat-Light', color: 'red', fontSize: 12}}>Maksmata</text>
                  :
                  <text style={{fontFamily: 'Montserrat-Light', color: 'green', fontSize: 12}}>Makstud</text>
                  }
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  {!item.customerPays && !item.payd ?
                  <text style={{fontFamily: 'Montserrat-Light', color: 'red', fontSize: 12, fontWeight: 'bold'}}> Maksab kauplus!</text>
                  :
                  <text style={{fontFamily: 'Montserrat-Light', color: 'green', fontSize: 12}}></text>
                  }
                </div>
                {!item.payd && item.customerPays && (
                  <div>
                <TouchableOpacity
                  onPress={() => this.makePayd(item.orderID)}>
                  <div style={{display: 'flex', flexDirection: 'row', width: 100, flexDirection: 'row', padding: 5, border: '0.5px solid black', alignItems: 'center', justifyContent: 'center',  borderRadius: 10, marginRight: 5}}>
                    <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Makstud</text>
                  </div>
                </TouchableOpacity>
                </div>
                )}
                {item.inProgress && !item.archived && !item.finished && (
                <div>
                {this.state.confirmCancelID == item.orderID ? (
                  <div style={{flexDirection: 'row'}}>
                    <TouchableOpacity
                      onPress={() => this.makeCanceled(item.orderID)}>
                      <div style={{flexDirection: 'row', padding: 5, borderWidth: 1, borderRadius: 10, borderColor: 'red', marginRight: 5}}>
                        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 10, color: 'red'}}>Kinnita</text>
                      </div>
                    </TouchableOpacity>
                    <textInput
                      onChangetext={(newPrice) => this.setState({newPrice})}
                      style={{width: 60, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', color: 'red', textAlign: 'center'}}
                      placeholder={this.state.newPrice}
                    />
                    <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>€</text>
                  </div>
                ):(
                  <TouchableOpacity
                    onPress={() => this.setState({confirmCancelID: item.orderID, newPrice: item.price})}>
                    <div style={{display: 'flex', width: 100, flexDirection: 'row', padding: 5, alignItems: 'center', justifyContent: 'center', border: '0.5px solid black', marginRight: 5}}>
                      <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Katkesta</text>
                    </div>
                  </TouchableOpacity>
                )}
                </div>
                )}
              </div>
              }
            <div>
              {item.type == 'move' ?
              <Link className="nav-item nav-link" to={{pathname: '/privateOrderDetail', state: { orderID: item.orderID }}}>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 16, color: '#e53051'}}>Detailid</text>
              </Link>
              :
              <Link className="nav-item nav-link" to={{pathname: '/driverOrderDetail', state: { orderID: item.orderID }}}>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 16, color: '#e53051'}}>Detailid</text>
              </Link>
              }
              <TouchableOpacity
                onPress={() => this.sendToArchive(item.deliveryDate, item.deliveryTime, item.orderID, item.loadingDate, item.to, item.from, item.price, item.orders, item.workerID, item.phone, item.customerPays, item.type, item.shopID, item.shopName, item.workerCompany, item.payd)}>
                <div style={{display: 'flex', width: 100, flexDirection: 'row', padding: 5, alignItems: 'center', justifyContent: 'center', border: '0.5px solid black', marginRight: 5}}>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Arhiivi</text>
                </div>
              </TouchableOpacity>
              </div>
            </div>
          );
        })}
        </div>
      </div>
    );
  }
}

export default Transport;
