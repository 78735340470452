import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light" style={{backgroundColor: '#2424d2'}}>
        <Link style={{color: 'white', fontFamily: 'Montserrat-Regular'}} className="nav-item nav-link" to="/">
          <img src={require('../img/logo.png')} style={{width: '50%'}}/>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
          style={{backgroundColor: '#2424d2'}}
        >
            <div className="navbar-nav" style={{backgroundColor: '#2424d2'}}>
              <Link style={{color: 'white', fontFamily: 'Montserrat-Regular'}} className="nav-item nav-link" to="/">
                Kauplused
              </Link>
              <Link style={{color: 'white', fontFamily: 'Montserrat-Regular'}} className="nav-item nav-link" to="/driversPage">
                Driverid
              </Link>
              <Link style={{color: 'white', fontFamily: 'Montserrat-Regular'}} className="nav-item nav-link" to="/privateUsers">
                Eraisikud
              </Link>
              <Link style={{color: 'white', fontFamily: 'Montserrat-Regular'}} className="nav-item nav-link" to="/allPrivateOrders">
                Eraisikute tellimused
              </Link>
              <Link style={{color: 'white', fontFamily: 'Montserrat-Regular'}} className="nav-item nav-link" to="/transport">
                Transport
              </Link>
              <Link style={{color: 'white', fontFamily: 'Montserrat-Regular'}} className="nav-item nav-link" to="/smallPacks">
                Väikepakid
              </Link>
              <Link style={{color: 'white', fontFamily: 'Montserrat-Regular'}} className="nav-item nav-link" to="/regularUsers">
                Logi välja
              </Link>
            </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
