import React, { Component } from 'react';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class PrivateUsers extends React.Component {

  constructor() {
    super();
    this.state = {
      userList: [],
      isLoading: true,
      count: 0
    }
  }

  componentDidMount() {
    var auth = firebase.auth();

    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        this.getShopList()
      }
    });
  }

  getShopList() {
    firebase.database().ref('users/move').orderByChild('shopName').on('value', data => {
      var userList = [];
      const count = data.numChildren();
      data.forEach(child => {
        userList.push({
          lastName: child.val().lastName,
          firstName: child.val().firstName,
          lastSeen: child.val().lastSeen,
          phone: child.val().phone,
          runningBuild: child.val().runningBuild,
          userID: child.val().userID,
          eMail: child.val().eMail
        });
    })
      this.setState({userList: userList.sort((b, a) => a.lastName - b.lastName), count: count}, function() {
        this.setState({isLoading: false})
      })
    })
  }

  render() {

    if(this.state.isLoading) {
      return (
        <text>Loading...</text>
      )
    }

    return (
      <div>
        <Header />
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', border: '1px solid black', justifyContent: 'space-between'}}>
          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Eraisikuid kokku: <text style={{fontWeight: 'normal'}}>{this.state.count}</text></text>
          {this.state.userList.map(item => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', border: '0.1px dashed grey', alignItems: 'center', padding: 5, justifyContent: 'space-between'}}>
                {item.count > 0 &&
                <div style={{backgroundColor: 'red', width: 10, height: 10, borderRadius: 5, marginRight: 5}}>
                </div>
                }
                <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, marginRight: 5}}>{item.lastName}</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14}}>{item.firstName}</text>
                  </div>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: '#2424d2'}}>{item.phone} | {item.eMail} | {item.userID}</text>
                  <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'red'}}> Ver: {item.runningBuild}</text>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'flex-end'}}>
                  <Link className="nav-item nav-link" to={{pathname: '/privateOrders', state: { userID: item.userID}}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, color: '#e53051'}}>Tellimused</text>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default PrivateUsers;
