import React, { Component } from 'react';
import { View, Text, Dimensions, FlatList, TouchableOpacity, ActivityIndicator, Image, TextInput} from 'react-native-web';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';
import moment from 'moment';

class AllPrivateOrders extends React.Component {

  constructor() {
    super();
    this.state = {
      orders: [],
      isLoading: true,
      count: 0
    }
  }

  componentDidMount() {

    var auth = firebase.auth();

    // on state changed
    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        const adminID = auth.currentUser.uid;
        this.setState({adminID: adminID}, function() {
          this.getOrders();
        })
      }
    });
  }

  getOrders() {
    firebase.database().ref('orders').orderByChild('type').equalTo('move').on('value', data => {
      var orders = [];
      const count = data.numChildren();
      let sum = 0;
      let total = 0;
      let shopTotal = 0;
      let rate = 0

      data.forEach(child => {
        orders.push({
          from: child.val().shopAddress,
          to: child.val().placeName,
          orderDate: child.val().orderDate,
          orderTime: child.val().orderTime,
          orderID: child.key,
          workerCompany: child.val().workerCompany,
          workerID: child.val().workerID,
          inProgress: child.val().inProgress,
          finished: child.val().finished,
          payd: child.val().payd,
          customerPays: child.val().customerPays,
          shopName: child.val().shopName,
          archived: child.val().archived,
          price: Number(child.val().price),
          deliveryDate: child.val().deliveryDate,
          deliveryTo: child.val().deliveryTo
        });
    })
      this.setState({orders: orders.sort((a, b) => b.orderID - a.orderID), count: count, sum: sum, total: total, shopTotal: shopTotal}, function() {
        this.setState({isLoading: false})
      })
    })
  }

  timestamp(date, time) {
    const formDate = moment(date, "DD/MM/YYYY").format('YYYY/MM/DD')
    const timestamp = moment(`${formDate} ${time}`).unix()
    return timestamp
  }

  status(inProgress, finished, workerCompany, archived, deliveryDate, deliveryTo) {
    if((this.timestamp(deliveryDate, deliveryTo) > (new Date().getTime().toString() / 1000).toFixed(2)) && !inProgress && !finished && !archived) {
      return <Text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'green'}}>Ootel!</Text>
    }
    if((this.timestamp(deliveryDate, deliveryTo) < (new Date().getTime().toString() / 1000).toFixed(2)) && !inProgress && !finished && !archived) {
      return <Text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>Aegunud!</Text>
    }
    if (finished == true) {
      return <Text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'green'}}>Lõpetatud</Text>
    }
    if (inProgress == true) {
      return <Text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>Tööd teostab <Text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>{workerCompany}</Text></Text>
    }
    if (inProgress == false && finished == false && archived == true) {
      return <Text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>Katkestatud</Text>
    }
  }

  makeCanceled(item) {
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var date = new Date().getDate();
    var kuu = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if(min < 10) {
      var minute = "0" + min;
    } else {
      var minute = min;
    }
    if(kuu < 10) {
      var month = "0" + kuu;
    } else {
      var month = kuu;
    }
    const finishDate = date + '/' + month + '/' + year;
    const finishTime = hours + ':' + minute;

    firebase.database().ref('orders').child(item).update({
      finished: false,
      arrivingDate: finishDate,
      arrivingTime: finishTime,
      archived: true,
      inProgress: false,
      canceledBy: this.state.adminID,
      price: this.state.newPrice
    })
  }

  makePayd(item) {
    firebase.database().ref('orders').child(item).update({
      payd: true
    })
  }

  render() {
    return (
      <div>
        <Header />
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', border: '1px solid black', justifyContent: 'space-between'}}>
          {this.state.orders.map(item => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', width: '100%', border: '0.1px dashed grey', alignItems: 'center', padding: 5, justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}>{item.from}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}> -> {item.to}</text>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>ID: {item.orderID}</text>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                  <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Staatus: </text>
                  {item.archived && item.workerCompany == '' ? (
                    <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>Katkestatud!</text>
                  ):(
                  <div>
                  {this.status(item.inProgress, item.finished, item.workerCompany, item.archived, item.deliveryDate, item.deliveryTo)}
                  </div>
                  )}
                </div>
                {item.workerCompany != '' &&
                <div style={{flexDirection: 'column', justifyContent: 'space-between', width: '30%'}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, marginRight: 5}}>Arve: </text>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'red'}}>{(item.price * this.state.rate).toFixed(2)} € </text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    {!item.payd ?
                    <text style={{fontFamily: 'Montserrat-Light', color: 'red', fontSize: 12}}>Maksmata</text>
                    :
                    <text style={{fontFamily: 'Montserrat-Light', color: 'green', fontSize: 12}}>Makstud</text>
                    }
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    {!item.customerPays == false?
                    <text style={{fontFamily: 'Montserrat-Light', color: 'red', fontSize: 12, fontWeight: 'bold'}}> Maksab kuller!</text>
                    :
                    <text style={{fontFamily: 'Montserrat-Light', color: 'green', fontSize: 12}}></text>
                    }
                  </div>
                  {!item.customerPays && !item.payd && (
                  <TouchableOpacity
                    onPress={() => this.makePayd(item.orderID)}>
                    <div style={{display: 'flex', flexDirection: 'row', width: 100, flexDirection: 'row', padding: 5, border: '0.5px solid black', alignItems: 'center', justifyContent: 'center',  borderRadius: 10, marginRight: 5}}>
                      <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Makstud</text>
                    </div>
                  </TouchableOpacity>
                  )}
                  {item.inProgress && !item.archived && !item.finished && (
                  <div>
                  {this.state.confirmCancelID == item.orderID ? (
                    <div style={{flexDirection: 'row'}}>
                      <TouchableOpacity
                        onPress={() => this.makeCanceled(item.orderID)}>
                        <div style={{flexDirection: 'row', padding: 5, borderWidth: 1, borderRadius: 10, borderColor: 'red', marginRight: 5}}>
                          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 10, color: 'red'}}>Kinnita</text>
                        </div>
                      </TouchableOpacity>
                      <textInput
                        onChangetext={(newPrice) => this.setState({newPrice})}
                        style={{width: 60, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', color: 'red', textAlign: 'center'}}
                        placeholder={this.state.newPrice}
                      />
                      <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>€</text>
                    </div>
                  ):(
                    <TouchableOpacity
                      onPress={() => this.setState({confirmCancelID: item.orderID, newPrice: item.price})}>
                      <div style={{display: 'flex', width: 100, flexDirection: 'row', padding: 5, alignItems: 'center', justifyContent: 'center', border: '0.5px solid black', marginRight: 5}}>
                        <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Katkesta</text>
                      </div>
                    </TouchableOpacity>
                  )}
                  </div>
                  )}
                </div>
                }
              <div>
                <Link className="nav-item nav-link" to={{pathname: '/privateOrderDetail', state: { orderID: item.orderID }}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 16, color: '#e53051'}}>Detailid</text>
                </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default AllPrivateOrders;
