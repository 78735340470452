import React, { Component } from 'react';
import { View, Text, Dimensions, FlatList, TouchableOpacity, ActivityIndicator, Image } from 'react-native-web';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class DriverOrders extends React.Component {

  constructor() {
    super();
    this.state = {
      orders: [],
      isLoading: true,
      count: 0,
    }
  }

  componentDidMount() {

    var auth = firebase.auth();

    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        const { driverID } = this.props.location.state;
        const adminID = auth.currentUser.uid;
        this.setState({driverID: driverID, adminID: adminID}, function() {
          this.getOrders();
        })
      }
    });
  }

  getOrders() {
    firebase.database().ref('orders').orderByChild('workerID').equalTo(this.state.driverID).on('value', data => {
      firebase.database().ref('users/drivers').child(this.state.driverID).on('value', user => {
      var orders = [];
      const count = data.numChildren();
      const company = user.val().company
      const driver = user.val().driverName
      const rate = Number(user.val().rate)
      let sum = 0
      let shop = 0
      let total = 0
      data.forEach(child => {
          if(child.val().payd != true && child.val().customerPays == false && child.val().type != 'private') {
            shop = shop + Number(child.val().price)
          }
          if(child.val().payd != true && child.val().customerPays == true && child.val().type != 'private') {
            sum = sum + Number(child.val().price)
          }
          total = total + Number(child.val().price)
          orders.push({
            from: child.val().shopAddress,
            to: child.val().placeName,
            orderDate: child.val().orderDate,
            orderTime: child.val().orderTime,
            orderID: child.key,
            loadingDate: child.val().loadingDate,
            loadingTime: child.val().loadingTime,
            workerCompany: child.val().workerCompany,
            inProgress: child.val().inProgress,
            finished: child.val().finished,
            price: Number(child.val().price),
            customerPays: child.val().customerPays,
            payd: child.val().payd ? true : false,
            user: user.val().company,
            driver: user.val().driver,
            type: child.val().type,
            orders: child.val().orders,
            workerID: child.val().workerID,
            phone: child.val().phone,
            shopID: child.val().shopID,
            shopName: child.val().shopName,
            deliveryDate: child.val().arrivingDate,
            deliveryTime: child.val().arrivingTime
          });
    })
      this.setState({orders: orders.sort((a, b) => b.orderID - a.orderID), count: count, company: company, driver: driver, sum: sum, rate: rate, shop: shop, total: total}, function() {
        this.setState({isLoading: false})
      })
    })
    })
  }

  status(inProgress, finished, workerCompany) {
    if (finished == true) {
      return <Text style={{color: 'green'}}>Lõpetatud</Text>
    }
    if (inProgress == true) {
      return 'Tööd teostab ' + workerCompany
    } else {
      return 'Ootab vastust'
    }
  }

  makePayd(item) {
    firebase.database().ref('orders').child(item).update({
      payd: true,
      madePayd: this.state.adminID
    })
  }

  sendToArchive(deliveryDate, deliveryTime, orderID, loadingDate, to, from, price, orders, workerID, phone, customerPays, type, shopID, shopName, workerCompany, payd) {
    firebase.database().ref('orders').child(orderID).off()
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var date = new Date().getDate();
    var kuu = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if(min < 10) {
      var minute = "0" + min;
    } else {
      var minute = min;
    }
    if(kuu < 10) {
      var month = "0" + kuu;
    } else {
      var month = kuu;
    }
    const finishDate = date + '/' + month + '/' + year;
    const finishTime = hours + ':' + minute;

    firebase.database().ref('archive').child(this.state.driverID).child(orderID).set({
      loadingDate: loadingDate,
      deliveryDate: deliveryDate,
      deliveryTime: deliveryTime,
      finishedDate: finishDate,
      finishedTime: finishTime,
      placeName: to,
      shopAddress: from,
      canceled: false,
      price: price,
      orders: orders,
      workerID: workerID,
      phone: phone,
      payd: payd,
      customerPays: customerPays,
      type: type,
      shopID: shopID,
      shopName: shopName
    })

    firebase.database().ref('archive').child(shopID).child(orderID).set({
      loadingDate: loadingDate,
      deliveryDate: deliveryDate,
      deliveryTime: deliveryTime,
      finishedDate: finishDate,
      finishedTime: finishTime,
      placeName: to,
      shopAddress: from,
      canceled: false,
      price: price,
      orders: orders,
      workerID: workerID,
      phone: phone,
      payd: payd,
      customerPays: customerPays,
      workerCompany: workerCompany
    })

    firebase.database().ref('orders').child(orderID).remove()
  }

  makeFinished(item) {
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var date = new Date().getDate();
    var kuu = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if(min < 10) {
      var minute = "0" + min;
    } else {
      var minute = min;
    }
    if(kuu < 10) {
      var month = "0" + kuu;
    } else {
      var month = kuu;
    }
    const finishDate = date + '/' + month + '/' + year;
    const finishTime = hours + ':' + minute;

    firebase.database().ref('orders').child(item).update({
      finished: true,
      arrivingDate: finishDate,
      arrivingTime: finishTime,
      archived: true,
      endedBy: this.state.adminID
    })
  }

  render() {

    return (
      <div>
        <Header />
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', border: '1px solid black', justifyContent: 'space-between'}}>
        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Tellimusi kokku: <text style={{fontWeight: 'normal', color: 'red'}}>{this.state.count}</text></text>
        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Kogusumma: <text style={{fontWeight: 'normal', color: 'red'}}>{this.state.total} €</text></text>
        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Teenustasud (v.a kaupluse maksed): <text style={{fontWeight: 'normal', color: 'red'}}>{(this.state.sum * this.state.rate).toFixed(2)} €</text></text>
        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Meie tasumata arved: <text style={{fontWeight: 'normal', color: 'red'}}>{((this.state.shop - (this.state.shop * this.state.rate))).toFixed(2)} €</text></text>
        <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Tasumata arveid: <text style={{fontWeight: 'normal', color: 'red'}}>{((this.state.sum * this.state.rate) - (this.state.shop - (this.state.shop * this.state.rate))).toFixed(2)} €</text></text>
          {this.state.orders.map(item => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', width: '100%', border: '0.1px dashed grey', alignItems: 'center', padding: 5, justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}>{item.from}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}> -> {item.to}</text>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>Esitati {item.orderDate} kell {item.orderTime}</text>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>ID: {item.orderID}</text>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                  <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Staatus: </text>
                  {item.archived && item.workerCompany == '' ? (
                    <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>Katkestatud!</text>
                  ):(
                  <div>
                  {this.status(item.inProgress, item.finished, item.workerCompany, item.archived)}
                  </div>
                  )}
                </div>
                {item.workerCompany != '' &&
                <div style={{flexDirection: 'column', justifyContent: 'space-between', width: '30%'}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, marginRight: 5}}>Arve: </text>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'red'}}>{(item.price * this.state.rate).toFixed(2)} € </text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    {!item.payd ?
                    <text style={{fontFamily: 'Montserrat-Light', color: 'red', fontSize: 12}}>Maksmata</text>
                    :
                    <text style={{fontFamily: 'Montserrat-Light', color: 'green', fontSize: 12}}>Makstud</text>
                    }
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    {!item.customerPays && !item.payd ?
                    <text style={{fontFamily: 'Montserrat-Light', color: 'red', fontSize: 12, fontWeight: 'bold'}}> Maksab kauplus!</text>
                    :
                    <text style={{fontFamily: 'Montserrat-Light', color: 'green', fontSize: 12}}></text>
                    }
                  </div>
                  {!item.payd && item.customerPays && (
                    <div>
                  <TouchableOpacity
                    onPress={() => this.makePayd(item.orderID)}>
                    <div style={{display: 'flex', flexDirection: 'row', width: 100, flexDirection: 'row', padding: 5, border: '0.5px solid black', alignItems: 'center', justifyContent: 'center',  borderRadius: 10, marginRight: 5}}>
                      <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Makstud</text>
                    </div>
                  </TouchableOpacity>
                  </div>
                  )}
                  {item.inProgress && !item.archived && !item.finished && (
                  <div>
                  {this.state.confirmCancelID == item.orderID ? (
                    <div style={{flexDirection: 'row'}}>
                      <TouchableOpacity
                        onPress={() => this.makeCanceled(item.orderID)}>
                        <div style={{flexDirection: 'row', padding: 5, borderWidth: 1, borderRadius: 10, borderColor: 'red', marginRight: 5}}>
                          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 10, color: 'red'}}>Kinnita</text>
                        </div>
                      </TouchableOpacity>
                      <textInput
                        onChangetext={(newPrice) => this.setState({newPrice})}
                        style={{width: 60, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', color: 'red', textAlign: 'center'}}
                        placeholder={this.state.newPrice}
                      />
                      <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>€</text>
                    </div>
                  ):(
                    <TouchableOpacity
                      onPress={() => this.setState({confirmCancelID: item.orderID, newPrice: item.price})}>
                      <div style={{display: 'flex', width: 100, flexDirection: 'row', padding: 5, alignItems: 'center', justifyContent: 'center', border: '0.5px solid black', marginRight: 5}}>
                        <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Katkesta</text>
                      </div>
                    </TouchableOpacity>
                  )}
                  </div>
                  )}
                </div>
                }
              <div>
                {item.type == 'move' ?
                <Link className="nav-item nav-link" to={{pathname: '/privateOrderDetail', state: { orderID: item.orderID }}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 16, color: '#e53051'}}>Detailid</text>
                </Link>
                :
                <Link className="nav-item nav-link" to={{pathname: '/driverOrderDetail', state: { orderID: item.orderID }}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 16, color: '#e53051'}}>Detailid</text>
                </Link>
                }
                <TouchableOpacity
                  onPress={() => this.sendToArchive(item.deliveryDate, item.deliveryTime, item.orderID, item.loadingDate, item.to, item.from, item.price, item.orders, item.workerID, item.phone, item.customerPays, item.type, item.shopID, item.shopName, item.workerCompany, item.payd)}>
                  <div style={{display: 'flex', width: 100, flexDirection: 'row', padding: 5, alignItems: 'center', justifyContent: 'center', border: '0.5px solid black', marginRight: 5}}>
                    <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Arhiivi</text>
                  </div>
                </TouchableOpacity>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DriverOrders;
