import React, { Component } from 'react';
import { View, Text, Dimensions, FlatList, TouchableOpacity, ActivityIndicator, Image } from 'react-native-web';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class DriversPage extends React.Component {

  constructor() {
    super();
    this.state = {
      driversList: [],
      isLoading: true
    }
    var auth = firebase.auth();

    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        this.getBuild()
      }
    });
  }

  getBuild() {
    firebase.database().ref('sysMessage').on('value', snap => {
      this.setState({version: snap.val().toBuildDrive})
    })
    this.getDriversList()
  }

  getDriversList() {
    firebase.database().ref('users/drivers').orderByChild('company').on('value', data => {
      var driversList = [];
      const count = data.numChildren();
      data.forEach(child => {
        let count = 0
        firebase.database().ref('archive').child(child.key).orderByChild('payd').equalTo(child.key).on('value', bill => {
          bill.forEach((child) => {
            if(child.val().customerPays != false && !child.val().payd && child.val().type != 'private') {
              count = count + 1
            }
          })
          if(child.key != 'missing') {
            driversList.push({
              company: child.val().company,
              driverName: child.val().driverName,
              driverNumber: child.val().driverNumber,
              eMail: child.val().eMail,
              rate: child.val().rate,
              driverID: child.key,
              count: count,
              legal: child.val().legal,
              version: child.val().runningBuild,
              type: child.val().type,
              version: child.val().runningBuild,
              lastSeen: child.val().lastSeen,
              maps: child.val().maps,
              orders: child.val().orders,
              isUnActive: child.val().isUnActive,
              isBlocked: child.val().isBlocked
            });
          }
    })
      })
      console.log(driversList)
      this.setState({driversList: driversList.sort((b, a) => a.company - b.company), count: count -1}, function() {
        this.setState({isLoading: false})
      })
    })
  }

  doReset() {
    var list = []
    firebase.database().ref('testusers/drivers').orderByChild('legal').equalTo(true).on('value', snap => {
      snap.forEach(child => {
        firebase.database().ref('testusers/drivers').child(child.key).update({
          legal: false
        })
      })

    })
  }

  reset(driverID) {
    firebase.database().ref('users/drivers').child(driverID).update({
      isBlocked: false,
      isUnActive: false
    })
    firebase.database().ref('users/drivers').child(driverID).child('offersViewed').remove()
  }


  render() {

    return (
      <div>
        <Header />
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', border: '1px solid black', justifyContent: 'space-between'}}>
          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Ettevõtteid kokku: <text style={{fontWeight: 'normal'}}>{this.state.count}</text></text>
          {this.state.driversList.map(item => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', border: '0.1px dashed grey', alignItems: 'center', padding: 5, justifyContent: 'space-between'}}>
                {item.count > 0 ?
                  <div style={{backgroundColor: 'red', width: 10, height: 10, borderRadius: 5, marginRight: 5}}>
                  </div>
                :
                  <div style={{backgroundColor: 'green', width: 10, height: 10, borderRadius: 5, marginRight: 5}}>
                  </div>
                }
                <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, marginRight: 5}}>{item.company}</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, marginRight: 5}}>({(item.rate*100).toFixed(2)} %)</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 12, marginRight: 5}}>{item.driverID}</text>
                  </div>
                  <div>
                    {this.state.version > item.version ?
                      <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'red'}}> Ver: {item.version}</text>
                    :
                      <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'green'}}> Ver: {item.version}</text>
                    }
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'blue'}}> {item.runningWeb ? 'Web' : ''}</text>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'red'}}> {item.type == 'disabled' && 'Peatatud!'}</text>
                  </div>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: '#2424d2'}}>{item.driverName} | {item.driverNumber} | {item.eMail} | {item.lastSeen}</text>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>{item.isBlocked && item.isUnActive && 'Blokeeritud'}</text>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>{item.isBlocked && 'Viimane vaatamine!'}</text>
                  {item.isBlocked && item.isUnActive &&
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      {this.state.confirmCancelID == item.driverID ? (
                        <div style={{flexDirection: 'row'}}>
                          <TouchableOpacity
                            onPress={() => this.reset(item.driverID)}>
                            <div style={{flexDirection: 'row', padding: 5, borderWidth: 1, borderRadius: 10, borderColor: 'red', marginRight: 5}}>
                              <text style={{fontFamily: 'Montserrat-Regular', fontSize: 10, color: 'red'}}>Kinnita</text>
                            </div>
                          </TouchableOpacity>
                        </div>
                      ):(
                        <TouchableOpacity
                          onPress={() => this.setState({confirmCancelID: item.driverID})}>
                          <div style={{display: 'flex', width: 50, flexDirection: 'row', padding: 5, alignItems: 'center', justifyContent: 'center', border: '0.5px solid black', marginRight: 5}}>
                            <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Taasta</text>
                          </div>
                        </TouchableOpacity>
                      )}
                    </div>
                  }
                  {item.isBlocked &&
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      {this.state.confirmCancelID == item.driverID ? (
                        <div style={{flexDirection: 'row'}}>
                          <TouchableOpacity
                            onPress={() => this.reset(item.driverID)}>
                            <div style={{flexDirection: 'row', padding: 5, borderWidth: 1, borderRadius: 10, borderColor: 'red', marginRight: 5}}>
                              <text style={{fontFamily: 'Montserrat-Regular', fontSize: 10, color: 'red'}}>Kinnita</text>
                            </div>
                          </TouchableOpacity>
                        </div>
                      ):(
                        <TouchableOpacity
                          onPress={() => this.setState({confirmCancelID: item.driverID})}>
                          <div style={{display: 'flex', width: 50, flexDirection: 'row', padding: 5, alignItems: 'center', justifyContent: 'center', border: '0.5px solid black', marginRight: 5}}>
                            <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Taasta</text>
                          </div>
                        </TouchableOpacity>
                      )}
                    </div>
                  }
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'flex-end'}}>
                  <Link className="nav-item nav-link" to={{pathname: '/driverOrders', state: { driverID: item.driverID}}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, color: '#e53051'}}>Aktiivsed</text>
                  </Link>
                  <Link className="nav-item nav-link" to={{pathname: '/driverArchive', state: { driverID: item.driverID}}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, color: '#e53051'}}>Lõpetatud</text>
                  </Link>
                  <Link className="nav-item nav-link" to={{pathname: '/driverDetails', state: { driverID: item.driverID}}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, color: '#e53051'}}>Profiil</text>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DriversPage;
