import React, { Component } from 'react';
import { View, Text, Dimensions, FlatList, TouchableOpacity, ActivityIndicator, Image, StyleSheet } from 'react-native-web';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class DriverDetails extends React.Component {

  constructor() {
    super();
    this.state = {
      orders: [],
      isLoading: true
    }
  }

  componentDidMount() {

    var auth = firebase.auth();

    // on state changed
    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        const { driverID } = this.props.location.state
        this.setState({driverID: driverID}, function() {
          this.getDriverData();
        });
      }
    });
  }

  getDriverData() {
    firebase.database().ref('users/drivers').child(this.state.driverID).on('value', snap => {
      this.setState({
        cities: snap.val().cities,
        client: snap.val().client,
        company: snap.val().company,
        driverName: snap.val().driverName,
        driverNumber: snap.val().driverNumber,
        eMail: snap.val().eMail,
        lastSeen: snap.val().lastSeen,
      }, function() {
        this.setState({isLoading: false})
      })
    })
   }

  render() {
    return (
      <div style={{backgroundColor: '#F4F4F4', height: '100%'}}>
        <Header />

        <div style={{backgroundColor: '#F4F4F4', height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'flex-start', padding: 5}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: 5, justifyContent: 'space-between'}}>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular'}}>{this.state.driverName}</text>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Light'}}>{this.state.company}</text>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '180', justifyContent: 'flex-start'}}>

              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', border: '1px solid black', width: '100%', padding: 5, paddingBottom: 0, backgroundColor: 'white'}}>
                <div style={{display: 'flex', flexDirection: 'column', marginRight: 10}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Telefon:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>E-Post:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Viimati sees:</text>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.driverNumber}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.eMail}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.lastSeen}</text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DriverDetails;

const styles = StyleSheet.create({
  shadows: {
    shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 3
  },
  smallIcon: {
    width: 20,
    height: 20,
    marginLeft: 5
  },
})
