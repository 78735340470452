import React, { Component } from 'react';
import { View, Text } from 'react-native-web';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { Link } from './routing';
import firebase from 'firebase';
import AllShops from './sections/shops/allShops';
import ShopDetails from './sections/shops/shopDetails';
import ShopOrders from './sections/shops/shopOrders';
import ShopArchive from './sections/shops/shopArchive';
import ShopArchiveDetails from './sections/shops/shopArchiveDetails';
import PrivateUsers from './sections/private/privateUsers';
import AllPrivateOrders from './sections/private/allPrivateOrders';
import PrivateOrders from './sections/private/privateOrders';
import PrivateOrderDetail from './sections/private/privateOrderDetail';
import DriversPage from './sections/users/driversPage';
import DriverOrders from './sections/users/driverOrders'
import DriverArchive from './sections/users/driverArchive'
import DriverOrderDetail from './sections/users/driverOrderDetail';
import DriverArchiveDetail from './sections/users/driverArchiveDetails';
import DriverDetails from './sections/users/driverDetails';
import Transport from './sections/transport';
import SmallPacks from './sections/smallPacks';

import Invoice from './sections/invoices/invoice';

var config = {
  apiKey: "AIzaSyDfZpKuIbpSMrGugNYdSlRVeBToThQ4wZg",
  authDomain: "pakk-271009.firebaseapp.com",
  databaseURL: "https://pakk-271009.firebaseio.com",
  projectId: "pakk-271009",
  storageBucket: "pakk-271009.appspot.com",
  messagingSenderId: "296781538622",
  appId: "1:296781538622:web:34b95c6e77bcf0b408f86f",
  measurementId: "G-QRYR2B97Y3"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

class App extends Component {

  render() {
    return (
      <View style={{backgroundColor: '#F4F4F4'}}>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              component={AllShops}
            />
            <Route
              exact
              path="/shopDetails"
              component={ShopDetails}
            />
            <Route
              exact
              path="/shopOrders"
              component={ShopOrders}
            />
            <Route
              exact
              path="/shopArchive"
              component={ShopArchive}
            />
            <Route
              exact
              path="/driversPage"
              component={DriversPage}
            />
            <Route
              exact
              path="/privateUsers"
              component={PrivateUsers}
            />
            <Route
              exact
              path="/allPrivateOrders"
              component={AllPrivateOrders}
            />
            <Route
              exact
              path="/privateOrders"
              component={PrivateOrders}
            />
            <Route
              exact
              path="/privateOrderDetail"
              component={PrivateOrderDetail}
            />
            <Route
              exact
              path="/driverOrders"
              component={DriverOrders}
            />
            <Route
              exact
              path="/driverArchive"
              component={DriverArchive}
            />
            <Route
              exact
              path="/driverArchiveDetail"
              component={DriverArchiveDetail}
            />
            <Route
              exact
              path="/driverOrderDetail"
              component={DriverOrderDetail}
            />
            <Route
              exact
              path="/driverDetails"
              component={DriverDetails}
            />
            <Route
              exact
              path="/invoice"
              component={Invoice}
            />
            <Route
              exact
              path="/transport"
              component={Transport}
            />
            <Route
              exact
              path="/smallPacks"
              component={SmallPacks}
            />
          </Switch>
        </Router>
      </View>
    );
  }
}
export default App;
