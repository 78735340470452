import React from 'react';
import { View, Text, Dimensions, ActivityIndicator, StyleSheet, FlatList, TextInput, TouchableOpacity } from 'react-native-web';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class DriverArchiveDetail extends React.Component {

  constructor() {
    super();
    this.state = {
      orders: [],
      isLoading: true,
    }
  }

  componentDidMount() {
    var auth = firebase.auth();

    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        const { orderID } = this.props.location.state
        const { driverID } = this.props.location.state

        this.setState({orderID: orderID, driverID: driverID}, function() {
          this.getDriveData();
        });
      }
    });

  }

  getDriveData() {
    firebase.database().ref('archive').child(this.state.driverID).child(this.state.orderID).on('value', snap => {
      this.setState({
        orderID: snap.key,
        canceled: snap.val().canceled,
        customerPays: snap.val().customerPays,
        deliveryTime: snap.val().deliveryTime,
        deliveryDate: snap.val().deliveryDate,
        loadingDate: snap.val().loadingDate,
        orders: snap.val().orders,
        payd: snap.val().payd,
        phone: snap.val().phone,
        placeName: snap.val().placeName,
        price: snap.val().price,
        shopAddress: snap.val().shopAddress,
        workerID: snap.val().workerID,
        special: snap.val().special
      }, function() {
        this.setState({isLoading: false});
      })
    })
  }

  render() {

    return (
      <div style={{backgroundColor: '#F4F4F4', height: '100%'}}>
        <Header />

        <div style={{backgroundColor: '#F4F4F4', height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'flex-start', padding: 5}}>
            {!this.state.canceled &&
            <div style={{display: 'flex', flexDirection: 'column', borderBottomWidth: 0.5, borderBottomColor: 'black', width: '100%', marginBottom: 10}}>
              <text style={{fontFamily: 'Montserrat-Regular', color: global.mainBlue, fontSize: 14, marginRight: 5}}>Kaup üle antud</text>
              <text style={{color: 'black', fontFamily: 'Montserrat-Light', fontSize: 14}}>{this.state.deliveryDate + ' kell ' + this.state.deliveryTime}</text>
              <text style={{color: 'black', fontFamily: 'Montserrat-Light', fontSize: 14}}>{this.state.workerID}</text>
            </div>
            }
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '180', justifyContent: 'flex-start'}}>

              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', border: '1px solid black', width: '100%', padding: 5, paddingBottom: 0, backgroundColor: 'white'}}>
                <div style={{display: 'flex', flexDirection: 'column', marginRight: 10}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>ID:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.orderID}</text>
                  </div>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Pealelaadimise aadress:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.shopAddress}</text>

                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Tarne aadress:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.placeName}</text>
                  {this.state.apartment && (
                    <div>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Korter/ruum nr.:</text>
                    <text style={{color: 'black', fontFamily: 'Montserrat-Light', fontSize: 12}}>{this.state.apartment}</text>
                    </div>
                  )}
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Tellimuse nr/nimetus</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.orders}</text>

                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Kontaktnumber:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.phone}</text>


                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Pealelaadimine:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.loadingDate}</text>

                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Mahalaadimine:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.deliveryDate} kell {this.state.deliveryTime}</text>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Teenuse maksumus:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.price} €</text><text style={{fontFamily: 'Montserrat-Regular', color: 'red', fontSize: 14, marginRight: 5}}>{this.state.customerPays ? ' Tasub klient' : ' Tasub kauplus'}</text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Kommentaar:</text>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.comment}</text>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    );

  }
}

export default DriverArchiveDetail;

const styles = StyleSheet.create({
  shadows: {
    shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 3
  },
  smallIcon: {
    width: 20,
    height: 20,
    marginLeft: 5
  },
})
