import React, { Component } from 'react';
import { View, Text, Dimensions, FlatList, TouchableOpacity, ActivityIndicator, Image, TextInput} from 'react-native-web';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class ShopOrders extends React.Component {

  constructor() {
    super();
    this.state = {
      orders: [],
      isLoading: true,
      count: 0
    }
  }

  componentDidMount() {

    var auth = firebase.auth();

    // on state changed
    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        const { shopID, shopName, contactName } = this.props.location.state;
        const adminID = auth.currentUser.uid;
        this.setState({shopID: shopID, shopName: shopName, contactName: contactName, adminID: adminID}, function() {
          this.getOrders();
        })
      }
    });
  }

  getOrders() {
    firebase.database().ref('orders').orderByChild('shopID').equalTo(this.state.shopID).on('value', data => {
      var orders = [];
      const count = data.numChildren();
      let sum = 0;
      let total = 0;
      let shopTotal = 0;
      let rate = 0

      data.forEach(child => {
          firebase.database().ref('users/drivers').child(child.val().workerID == '' ? 'missing' : child.val().workerID).on('value', user => {
            if(child.val().workerID == '') {
              rate = 0
            } else {
              rate = Number(user.val().rate)
            }
            if(child.val().finished && !child.val().customerPays && !child.val().payd) {
              total = total + Number(child.val().price)
              sum = sum + Number(child.val().price * Number(user.val().rate))
            }
            shopTotal = shopTotal + Number(child.val().price)
          orders.push({
            from: child.val().shopAddress,
            to: child.val().placeName,
            orderDate: child.val().orderDate,
            orderTime: child.val().orderTime,
            orderID: child.key,
            workerCompany: child.val().workerCompany,
            workerID: child.val().workerID,
            inProgress: child.val().inProgress,
            finished: child.val().finished,
            payd: child.val().payd,
            customerPays: child.val().customerPays,
            shopName: child.val().shopName,
            archived: child.val().archived,
            rate: rate,
            price: Number(child.val().price),
            type: child.val().type,
            arrivingDate: child.val().arrivingDate,
            arrivingTime: child.val().arrivingTime
          });
        })
    })
      this.setState({orders: orders.sort((a, b) => b.orderID - a.orderID), count: count, sum: sum, total: total, shopTotal: shopTotal}, function() {
        this.setState({isLoading: false})
      })
    })
  }

  status(inProgress, finished, workerCompany, archived) {
    if (finished == true) {
      return <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'green'}}>Lõpetatud</text>
    }
    if (inProgress == true) {
      return <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>Tööd teostab <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>{workerCompany}</text></text>
    }
    if (inProgress == false && finished == false && archived == false) {
      return <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>Vastuvõtmata</text>
    }
    if (inProgress == false && finished == false && archived == true) {
      return <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>Katkestatud</text>
    }
  }

  makeCanceled(item) {
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var date = new Date().getDate();
    var kuu = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if(min < 10) {
      var minute = "0" + min;
    } else {
      var minute = min;
    }
    if(kuu < 10) {
      var month = "0" + kuu;
    } else {
      var month = kuu;
    }
    const finishDate = date + '/' + month + '/' + year;
    const finishTime = hours + ':' + minute;

    firebase.database().ref('archive').child(item.orderID).child(item.orderID).off()

    firebase.database().ref('archive').child(item.workerID).child(item.orderID).set({
      loadingDate: item.loadingDate,
      deliveryDate: item.arrivingDate,
      deliveryTime: item.arrivingTime,
      finishedDate: finishDate,
      finishedTime: finishTime,
      placeName: item.to,
      shopAddress: item.from,
      canceled: false,
      price: item.price,
      orders: item.orders,
      workerID: item.workerID,
      phone: item.phone,
      payd: false,
      customerPays: item.customerPays,
      type: item.type,
      shopID: item.shopID,
      shopName: item.shopName
    })

    firebase.database().ref('archive').child(this.state.shopID).child(item.orderID).set({
      loadingDate: item.loadingDate,
      deliveryDate: item.arrivingDate,
      deliveryTime: item.arrivingTime,
      finishedDate: finishDate,
      finishedTime: finishTime,
      placeName: item.to,
      shopAddress: item.from,
      canceled: false,
      price: item.price,
      orders: item.orders,
      workerID: item.workerID,
      phone: item.phone,
      payd: false,
      customerPays: item.customerPays,
      type: item.type,
      shopID: item.shopID,
      shopName: item.shopName
    })

    firebase.database().ref('orders').child(item.orderID).remove()
  }

  makePayd(item) {
    firebase.database().ref('orders').child(item).update({
      payd: true,
      madePaydBy: this.state.adminID
    })
  }


  render() {

    if(this.state.isLoading) {
      return(
        <div>
          <text>loading</text>
        </div>
      )
    }
    return (
      <div>
        <Header />
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', border: '1px solid black', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: 5, border: '1px solid black', justifyContent: 'space-between', backgroundColor: 'white'}}>
          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 16}}>{this.state.shopName} - <text style={{fontFamily: 'Montserrat-Light', fontSize: 14}}>{this.state.contactName}</text></text>
          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 16}}>Tellimusi kokku: <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>{this.state.count}</text></text>
          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 16}}>Tellimuste kogusumma: <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>{this.state.shopTotal} €</text></text>
          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 16}}>Tasumata arveid: <text style={{fontWeight: 'normal', color: 'red'}}>{this.state.total} € <text style={{fontWeight: 'normal', color: 'black'}}>({(this.state.sum).toFixed(2)} €)</text></text></text>
        </div>
          {this.state.orders.map(item => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', width: '100%', border: '0.1px dashed grey', alignItems: 'center', padding: 5, justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}>{item.from}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}> -> {item.to}</text>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>Esitati {item.orderDate} kell {item.orderTime}</text>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12}}>ID: {item.orderID}</text>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                  <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Staatus: </text>
                  {item.archived && item.workerCompany == '' ? (
                    <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: 'red'}}>Katkestatud!</text>
                  ):(
                  <div>
                  {this.status(item.inProgress, item.finished, item.workerCompany, item.archived)}
                  </div>
                  )}
                </div>
                {item.workerCompany != '' &&
                <div style={{flexDirection: 'column', justifyContent: 'space-between', width: '30%'}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, marginRight: 5}}>Arve: </text>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'red'}}>{(item.price * item.rate).toFixed(2)} € </text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    {!item.payd ?
                    <text style={{fontFamily: 'Montserrat-Light', color: 'red', fontSize: 12}}>Maksmata</text>
                    :
                    <text style={{fontFamily: 'Montserrat-Light', color: 'green', fontSize: 12}}>Makstud</text>
                    }
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    {!item.customerPays == false?
                    <text style={{fontFamily: 'Montserrat-Light', color: 'red', fontSize: 12, fontWeight: 'bold'}}> Maksab kuller!</text>
                    :
                    <text style={{fontFamily: 'Montserrat-Light', color: 'green', fontSize: 12}}></text>
                    }
                  </div>
                  {!item.customerPays && !item.payd && (
                  <TouchableOpacity
                    onPress={() => this.makePayd(item.orderID)}>
                    <div style={{width: 100, flexDirection: 'row', padding: 5, border: '0.5px solid black', borderRadius: 10, marginRight: 5}}>
                      <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Makstud</text>
                    </div>
                  </TouchableOpacity>
                  )}
                  {item.inProgress && !item.archived && !item.finished && (
                  <div>
                  {this.state.confirmCancelID == item.orderID ? (
                    <div style={{flexDirection: 'row'}}>
                      <TouchableOpacity
                        onPress={() => this.makeCanceled(item.orderID)}>
                        <div style={{flexDirection: 'row', padding: 5, borderWidth: 1, borderRadius: 10, borderColor: 'red', marginRight: 5}}>
                          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 10, color: 'red'}}>Kinnita</text>
                        </div>
                      </TouchableOpacity>
                      <TextInput
                        onChangetext={(newPrice) => this.setState({newPrice})}
                        style={{width: 60, height: 30, borderWidth: 0.5, borderRadius: 10, fontFamily: 'Montserrat-Regular', color: 'red', textAlign: 'center'}}
                        placeholder={this.state.newPrice}
                      />
                      <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>€</text>
                    </div>
                  ):(
                    <TouchableOpacity
                      onPress={() => this.setState({item})}>
                      <div style={{display: 'flex', width: 100, flexDirection: 'row', padding: 5, alignItems: 'center', justifyContent: 'center', border: '0.5px solid black', marginRight: 5}}>
                        <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Lõpeta sõit</text>
                      </div>
                    </TouchableOpacity>
                  )}
                  </div>
                  )}
                </div>
                }
              <div>
              {item.type == 'move' ?
              <Link className="nav-item nav-link" to={{pathname: '/privateOrderDetail', state: { orderID: item.orderID }}}>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 16, color: '#e53051'}}>Detailid</text>
              </Link>
              :
              <Link className="nav-item nav-link" to={{pathname: '/driverOrderDetail', state: { orderID: item.orderID }}}>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 16, color: '#e53051'}}>Detailid</text>
              </Link>
              }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ShopOrders;
