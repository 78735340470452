import React, { Component } from 'react';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class AllShops extends React.Component {

  constructor() {
    super();
    this.state = {
      shopList: [],
      isLoading: true,
      count: 0
    }
  }

  componentDidMount() {
    var auth = firebase.auth();

    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        this.getShopList()
      }
    });
  }

  getShopList() {
    firebase.database().ref('users/shops').orderByChild('shopName').on('value', data => {
      var shopList = [];
      const count = data.numChildren();
      data.forEach(child => {
        let count = 0
        firebase.database().ref('orders').orderByChild('shopID').equalTo(child.key).on('value', bill => {
          bill.forEach((child) => {
            if(child.val().payd != true && child.val().customerPays == false) {
              count = count + 1
            }
          })
          shopList.push({
            shopName: child.val().shopName,
            company: child.val().company,
            contactName: child.val().contactName,
            contactPhone: child.val().contactPhone,
            special: child.val().special,
            shopID: child.key,
            logo: child.val().logo,
            count: count,
            legal: child.val().legal,
            version: child.val().runningBuild,
            runningWeb: child.val().runningWeb,
            type: child.val().type
          });
        })
    })
      this.setState({shopList: shopList.sort((b, a) => a.shopName - b.shopName), count: count}, function() {
        this.setState({isLoading: false})
      })
    })
  }

  render() {

    if(this.state.isLoading) {
      return (
        <text>Loading...</text>
      )
    }

    return (
      <div>
        <Header />
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', border: '1px solid black', justifyContent: 'space-between'}}>
          <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12}}>Ettevõtteid kokku: <text style={{fontWeight: 'normal'}}>{this.state.count}</text></text>
          {this.state.shopList.map(item => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', border: '0.1px dashed grey', alignItems: 'center', padding: 5, justifyContent: 'space-between'}}>
                {item.count > 0 &&
                <div style={{backgroundColor: 'red', width: 10, height: 10, borderRadius: 5, marginRight: 5}}>
                </div>
                }
                <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14}}>{item.shopName} {item.special && ' (**)'}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14}}>{item.company}</text>
                  <div>
                    {this.state.version > item.version ?
                      <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'red'}}> Ver: {item.version}</text>
                    :
                      <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'green'}}> Ver: {item.version}</text>
                    }
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'blue'}}> {item.runningWeb ? 'Web' : ''}</text>
                    <text style={{fontFamily: 'Montserrat-Regular', fontSize: 12, color: 'red'}}> {item.type == 'disabled' && 'Peatatud!'}</text>
                  </div>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: '#2424d2'}}>{item.contactName} | {item.contactPhone}</text>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 12, color: '#2424d2'}}>{item.shopID}</text>

                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'flex-end'}}>
                  <Link className="nav-item nav-link" to={{pathname: '/shopDetails', state: { shopID: item.shopID }}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, color: '#e53051'}}>Profiil</text>
                  </Link>
                  <Link className="nav-item nav-link" to={{pathname: '/shopOrders', state: { shopID: item.shopID, contactName: item.contactName, shopName: item.shopName }}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, color: '#e53051'}}>Aktiivsed</text>
                  </Link>
                  <Link className="nav-item nav-link" to={{pathname: '/shopArchive', state: { shopID: item.shopID, contactName: item.contactName, shopName: item.shopName }}}>
                    <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, color: '#e53051'}}>Lõpetatud</text>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default AllShops;
