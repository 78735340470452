import React, { Component } from 'react';
import { View, Text, Dimensions, FlatList, TouchableOpacity, ActivityIndicator, Image, StyleSheet } from 'react-native-web';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class ShopArchiveDetails extends React.Component {

  constructor() {
    super();
    this.state = {
      orders: [],
      isLoading: true
    }
  }

  componentDidMount() {

    var auth = firebase.auth();

    // on state changed
    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        const { shopID } = this.props.location.state
        this.setState({shopID: shopID}, function() {
          this.getShopData();
        });
      }
    });
  }

  getShopData() {
    firebase.database().ref('users/shops').child(this.state.shopID).on('value', snap => {
      this.setState({
        address: snap.val().address,
        city: snap.val().city,
        company: snap.val().company,
        contactEmail: snap.val().contactEmail,
        contactName: snap.val().contactName,
        contactPhone: snap.val().contactPhone,
        logo: snap.val().logo,
        shopName: snap.val().shopName,
        regnumber: snap.val().regnumber,
        lastSeen: snap.val().lastSeen
      }, function() {
        this.setState({isLoading: false})
      })
    })
   }

  render() {
    return (
      <div style={{backgroundColor: '#F4F4F4', height: '100%'}}>
        <Header />

        <div style={{backgroundColor: '#F4F4F4', height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'flex-start', padding: 5}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <img src={this.state.logo} style={{width: 50, height: 50, marginRight: 5}} />
              <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: 5, justifyContent: 'space-between'}}>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular'}}>{this.state.shopName}</text>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Light'}}>{this.state.company}</text>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '180', justifyContent: 'flex-start'}}>

              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', border: '1px solid black', width: '100%', padding: 5, paddingBottom: 0, backgroundColor: 'white'}}>
                <div style={{display: 'flex', flexDirection: 'column', marginRight: 10}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Kontaktisik:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Telefon:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>E-Post:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Asukoht:</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Light', fontSize: 14, paddingLeft: 5}}>Viimati sees:</text>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.contactName}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.contactPhone}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.contactEmail}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.address}</text>
                  <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 14, paddingLeft: 5}}>{this.state.lastSeen}</text>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShopArchiveDetails;

const styles = StyleSheet.create({
  shadows: {
    shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 3
  },
  smallIcon: {
    width: 20,
    height: 20,
    marginLeft: 5
  },
})
