import React from 'react';
import { View, Text, Dimensions, ActivityIndicator, StyleSheet, Image } from 'react-native-web';
import Header from '../../helpers/header';
import firebase from 'firebase';
import {Link} from '../../routing';

class Invoice extends React.Component {

  constructor() {
    super();
    this.state = {
      orders: [],
      isLoading: false,
    }
  }

  componentDidMount() {
    var auth = firebase.auth();

    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        const { driverID } = this.props.location.state
        this.setState({driverID: driverID}, function() {
          this.getDriverData();
        });
      }
    });
  }

  getDriverData() {
    firebase.database().ref('users/drivers').child(this.state.driverID).on('value', snap => {
      this.setState({
        company: snap.val().company,
        driverName: snap.val().driverName,
        regnumber: snap.val().regnumber,
        billNr: snap.val().billNr
      })
    })
    this.getBillData()
  }

  getBillData(){
    firebase.database().ref('orders').orderByChild('workerID').equalTo(this.state.driverID).on('value', snap => {
      var orders = []
      snap.forEach(child => {
        if(child.val().inProgress && child.val().finished && child.val().archived && !child.val().payd && child.val().customerPays) {
          orders.push({
            loadingDate: child.val().loadingDate,
            startPoint: child.val().shopAddress,
            endPoint: child.val().placeName,
            price: child.val().price,
            isLoading: false
          })
        }
      })
    })
  }

  render() {
    return (
      <View style={{width: Dimensions.get('window').width, height: Dimensions.get('window').height, backgroundColor: 'white'}}>
        <View style={{flexDirection: 'column', width: Dimensions.get('window').width*0.85, height: Dimensions.get('window').height, marginTop: 20, backgroundColor: 'white', justifyContent: 'flex-start', alignSelf: 'center', alignItems: 'center'}}>
          {this.state.isLoading ? (
            <ActivityIndicator />
          ):(
            <View style={{flexDirection: 'column', alignItems: 'flex-start', width: Dimensions.get('window').width * 0.85}}>
              <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                <Image source={require('../../img/logo_pakk.png')} style={{width: 130, resizeMode: 'contain', paddingLeft: 5}} />
                <View style={{width: 300, height: 60, backgroundColor: '#2424d2'}}>
                </View>
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default Invoice;

const styles = StyleSheet.create({
  shadows: {
    shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 3
  },
  smallIcon: {
    width: 20,
    height: 20,
    marginLeft: 5
  },
})
