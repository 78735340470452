import React, { Component } from 'react';
import { View, Text, Dimensions, FlatList, TouchableOpacity, ActivityIndicator, Image } from 'react-native-web';
import Header from '../helpers/header';
import firebase from 'firebase';
import {Link} from '../routing';

class SmallPacks extends React.Component {

  constructor() {
    super();
    this.state = {
      smallPacksList: [],
      isLoading: true
    }
    var auth = firebase.auth();

    auth.onAuthStateChanged(firebaseUser => {
      // check email
      if(!firebaseUser){

        window.location= 'login.html'
      }  else {
        this.gettransports()
      }
    });
  }

  gettransports() {
    firebase.database().ref('smallPacks').on('value', data => {
      var smallPacksList = [];
      const count = data.numChildren();
      data.forEach(child => {
        smallPacksList.push({
          order: child.key,
          start: child.val().start,
          startRoom: child.val().startRoom,
          startPhone: child.val().startPhone,
          finish: child.val().finish,
          finishRoom: child.val().finishRoom,
          finishPhone: child.val().finishPhone,
          packtype: child.val().packtype,
          date: child.val().date,
          time: child.val().time
        });
      })
      this.setState({smallPacksList: smallPacksList.sort((a, b) => b.order - a.order)}, function() {
        this.setState({isLoading: false})
      })
    })
  }

  render() {

    return (
      <div>
        <Header />
        <div>
        {this.state.smallPacksList.map(item => {
          return (
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', border: '0.1px dashed grey', alignItems: 'center', padding: 5, justifyContent: 'space-between'}}>
              <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 13, fontWeight: 'bold'}}>{item.packtype} | {item.date} {item.time}</text>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}>{item.start} {item.startRoom != '' && '(' + item.startRoom + ')'} | {item.startPhone}</text>
                <text style={{display: 'flex', fontFamily: 'Montserrat-Regular', fontSize: 12}}>{item.finish} {item.finishRoom != '' && '(' + item.finishRoom + ')'} | {item.finishPhone}</text>
              </div>
            <div>
              <TouchableOpacity
                onPress={() => this.sendToArchive(item.deliveryDate, item.deliveryTime, item.orderID, item.loadingDate, item.to, item.from, item.price, item.orders, item.workerID, item.phone, item.customerPays, item.type, item.shopID, item.shopName, item.workerCompany, item.payd)}>
                <div style={{display: 'flex', width: 100, flexDirection: 'row', padding: 5, alignItems: 'center', justifyContent: 'center', border: '0.5px solid black', marginRight: 5}}>
                  <text style={{fontFamily: 'Montserrat-Light', fontSize: 10}}>Arhiivi</text>
                </div>
              </TouchableOpacity>
              </div>
            </div>
          );
        })}
        </div>
      </div>
    );
  }
}

export default SmallPacks;
